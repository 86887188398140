.mobile {
  @media  screen and (max-width: 820px){
    display: flex;
  }
  @media screen and (min-width: 821px) {
    display: none;
  }
}

.desktop {
  @media  screen and (max-width: 820px){
    display: none;
  }
  @media screen and (min-width: 821px) {
    display: flex;
  }
}
